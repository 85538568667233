<template>
  <base-section
    id="features"
    class="secondary pt-10"
  >
    <v-responsive
      class="mx-auto"
      max-width="1350"
    >
      <base-section-heading
        title="Nos missions d’intérêt général"
        style="color: white;"
      />
      <v-container fluid>
        <v-row>
          <v-col
            v-for="card in cards"
            :key="card.title"
            cols="12"
            sm="4"
            md="3"
          >
            <base-info-card
              align="center"
              dark
              v-bind="card"
            >
              <template slot="footer">
                <base-btn :to="card.to">
                  {{ card.cta }}
                </base-btn>
              </template>
            </base-info-card>
            <!-- <div
              class="text-center"
              style="bottom: 0px;"
            >
            </div> -->
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionFeatures',

    data: () => ({
      cards: [
        {
          icon: 'mdi-newspaper',
          title: 'Soutenir le journalisme d’investigation',
          text: 'Soutien financier à des enquêtes journalistiques contribuant à un débat d’intérêt général, destinées à être publiées sous forme de livres.',
          cta: 'En savoir plus',
          to: '/post?id=671ab9c73a37ccf54ab6dceb',
        },
        {
          icon: 'mdi-newspaper-check',
          title: 'Lutter contre la désinformation',
          text: 'Soutien financier à des travaux de recherches scientifiques universitaires consacrés à la lutte contre la désinformation et la propagation de fausses nouvelles.',
          cta: 'En savoir plus',
          to: '/post?id=671ab9c73a37ccf54ab6dcec',
        },
        {
          icon: 'mdi-gavel',
          title: 'Défendre la liberté d’informer',
          text: 'Financement de frais de justice de médias indépendants dans le cadre de procédures judiciaires susceptibles de menacer la liberté de la presse. ',
          cta: 'En savoir plus',
          to: '/post?id=671ab9c73a37ccf54ab6dced',
        },
        {
          icon: 'mdi-human-male-board-poll',
          title: 'Eduquer aux médias',
          text: 'Accompagnement d’initiatives dédiées à une meilleure connaissance du journalisme et de l’information indépendante auprès des jeunes publics.',
          cta: 'En savoir plus',
          to: '/post?id=671ab9c73a37ccf54ab6dcee',
        },
      ],
    }),
  }
</script>
